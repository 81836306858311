var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("img", {
        attrs: {
          src: require("@/assets/contactUs/contact_us.png"),
          width: "100%",
          height: "400px",
        },
      }),
      _c("a-divider", [_vm._v("办事处")]),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "footer-box" }, [
              _vm._v("上海总部:上海市奉贤区金海公路6055号11幢5层"),
            ]),
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "footer-box" }, [
              _vm._v(
                "苏州办公室:江苏省苏州市吴中区后塘路2号太湖湾数字科技园61号楼502"
              ),
            ]),
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "footer-box" }, [_vm._v("广州办事处")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }