<template>
    <div>
        <img src="@/assets/contactUs/contact_us.png" width="100%" height="400px" />
        <a-divider>办事处</a-divider>
        <a-row :gutter="24">
            <a-col :span="24">
                <div class="footer-box">上海总部:上海市奉贤区金海公路6055号11幢5层</div>
            </a-col>
            <a-col :span="24">
                <div class="footer-box">苏州办公室:江苏省苏州市吴中区后塘路2号太湖湾数字科技园61号楼502</div>
            </a-col>
            <a-col :span="24">
                <div class="footer-box">广州办事处</div>
            </a-col>
        </a-row>
    </div>
</template>
<script>
export default {
    name: 'Contactus',
    data() {
        return {}
    }
}
</script>

<style scoped>
.contents {
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
}
/* 地图box */
.contents-box {
    width: 100%;
    box-shadow: 0px 2px 10px 0px rgb(175 175 175 / 50%);
}
#map_sh,
#map_nj,
#map_cd {
    width: 100%;
    min-height: 320px;
}
.contents-text {
    padding: 2rem;
}
.contents-text dt {
    font-size: 26px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.contents-text dd {
    font-size: 12px;
}
/* 办事处 */
.footer-box {
    width: 100%;
    height: 200px;
    font-size: 32px;
    box-shadow: 0px 2px 10px 0px rgb(175 175 175 / 50%);
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
}
</style>
